:root {
	--text-padding: 0.8rem;
	--default-border-size: 0.05em;
	--default-border-color: #999;

	--background-hue: 120;
	--background-saturation: 50%;
	--background-luminance: 95%;
	
	--default-border: var(--default-border-size) hsl(var(--background-hue), var(--background-saturation), calc(var(--background-luminance) - 5%)) outset;
	--selected-border: var(--default-border-size) hsl(var(--background-hue), var(--background-saturation), calc(var(--background-luminance) - 5%)) inset;
	--default-border-radius: 0.25em;
	--shadow-color: hsl(var(--background-hue), var(--background-saturation), calc(var(--background-luminance) - 5%));
	--transition-time: 0.2s;
	--background-color:  hsl(var(--background-hue), var(--background-saturation), var(--background-luminance));
	--transparent-background-color: hsla(var(--background-hue), var(--background-saturation), var(--background-luminance), 50%);
	--header-height: 2.5em;
	--button-gap: 0.2em;
}
* {
	box-sizing: border-box;
}
body {
	font-family: Arial, Helvetica, sans-serif;
    /*font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;*/
	background:var(--background-color);
}

button {
    cursor: pointer;
}

.clickable {
	cursor: pointer;
}

.button-row {
	display:flex;
	flex-direction: row;
	justify-content: flex-end;
}

.form-row input {
	width: 100%;
}

.form-row input[type=checkbox] {
	width: auto;
	vertical-align: middle;
	margin-right: 0.5em;
}


input,button {
	border: var(--default-border);
	border-radius: var(--default-border-radius);
	height: 2em;
	padding-left:0.5em;
	padding-right: 0.5em;
}

button.selected {
	border: inset;
}

.error {
	background-color: lightcoral;
	padding-left: 0.5em;
	padding-right: 0.5em;
	border-radius: var(--default-border-radius);
}

.notify {
	background-color: lightblue;
	padding-left: 0.5em;
	padding-right: 0.5em;
	border-radius: var(--default-border-radius);

}

.menu-section {
    border-bottom: var(--default-border);
	display: flex;
	flex-direction: column;
	gap: 0.25em;
}



.alert {
	color: red;
}
