
.page {
}


.main-container {
    padding-top: calc(var(--header-height));
    width: calc(100% - 1em);
    height: calc(100% - var(--header-height));

    display:flex;
    flex-direction: row;
}

.notes {
	--note-width: 18em;
	--note-margin: 0.5em;
    padding-top: 1em;

	column-width: 18em;
	column-count: auto;
	column-fill: balance;
	margin-left: 0.5em;
	/* transition: 3s; */
    flex-grow:1;
}
