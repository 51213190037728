.item {
	--palette-saturation: 80%;	
	--palette-luminance: 80%;
	padding-bottom: 1em;
}

.item.selected {
	position: fixed;
	height:100%;
	width: 100%;
	left: 0px;
	top: 0px;
	margin-left: 0px;
	background: var(--transparent-background-color);
	display: flex;
	z-index: 1;
}

.note {
    break-inside: avoid-column;
	/*border: var(--default-border);*/
	border-radius: var(--default-border-radius);
	display: flex;
	flex-direction: column;
	/*min-height: 10em;*/
	/* width: var(--note-width); */
	box-shadow: 0.0em 0.0em 1em 0.2em var(--shadow-color);
	background: white;
}

.note.selected {
	width: 36em;
	min-height: 20em;
	max-height: 100%;
	margin: auto;
	z-index: 2;
	display: flex;
}

.note-title {
	/* border-bottom: var(--default-border); */
	padding: var(--text-padding);
    font-weight: bold;
    /* This is for Chrome */
	word-break: break-word;
    /* And this for Firefox */
    word-wrap: anywhere;
    /* And the others !?!? */
}

.note-title > input {
	width: 100%;
	border: none;
	border-radius: 0;
	font-weight: bold;
}

.note-content {
	word-break: break-word;
	flex-grow: 1;
	padding: var(--text-padding);
	max-height: 20em;
	overflow-y: auto;
	overflow-x: hidden;
	flex-grow: 1;
}

.note-content[contenteditable]:focus {
	outline: 0px solid transparent;
}

.note.selected .note-content {
	max-height: calc(100vh - 10em);
}


.note-footer {
    /* This is so we can position absolutely the palette and tag list
     */
    position: relative;
	/* border-top: var(--default-border); */
	padding: var(--text-padding);
	opacity: 0;
	transition: var(--transition-time);
}

.note.selected > .note-footer {
	opacity: 1;
}

.note-footer i {
	margin-left: 0.5em;
	margin-right: 0.5em;
}
	
.note:hover >.note-footer {
	opacity: 1;
}

.note-palette > .icon-list, .note-palette > .icon-list {
    position: absolute;
    bottom: 2.5em;
    right: 0em;
    left: 0em;
    padding: var(--text-padding);
}

