.header {
	display: flex;
	align-items: center;
	
	/*padding-bottom: 1em;*/
	box-shadow: 0.0em 0.0em 1em 0.2em var(--shadow-color);
	background: white;
	padding-left: 0.5em;
	padding-right: 0.5em;
	gap: var(--button-gap);
	padding-bottom: 0.1em;
	padding-top: 0.1em;

	flex-grow: 0;
	flex-shrink: 0;

	height: var(--header-height);
	width: 100%;
	/*
	  Make it so it stays on top of the page
	  */
	position: fixed;
	/*
	  Put it above the notes in order not to see random icons when hovering 
	  on notes that are hidden by the header
   */
	z-index:1;

}

.header > button {
	padding-left: 0.75em;
	padding-right: 0.75em;
	min-width: 4em;
	height: 100%;
}

.filler {
	flex-grow:1;
}

#searchbox {
	order: 1;
}

@media( max-width: 30em) {
	.header > input:focus {
		width: 100%;
	}
}

@media( max-width: 30em) {
	.not-mobile {
		display: none;
	}
}

@media( max-width: 30em) {
	.header > input:focus ~ * {
		display: none;
	}
}
.header > .spacer {
	margin-left: 1em;
}

.header > .search {
	margin-left: 1em;
}
.main-title {
	font-size: 2em;
}

.header > .profile {
	padding-left: 1em;
}
