.modal-container {
    position: fixed;
    left:0;
    top:0;

    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: row;
    z-index: -1;

}

.modal-container.transparent {
    z-index: 100;
    background: var(--transparent-background-color);
}

.modal-container.opaque {
    z-index: 100;
    background: var(--background-color);

}


.modal {
    display: flex;
    flex-direction: column;
    border: var(--default-border);
    border-radius: var(--default-border-radius);
    background: white;
    line-height: 2em;
    min-width: 20em;
}

.modal-header {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
}

.modal-body {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
}

.modal-footer {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.modal-footer button {
    width: 100%;
}