.profile-header {
	display: flex;
	align-items: center;
	
	/*padding-bottom: 1em;*/
	box-shadow: 0.0em 0.0em 1em 0.2em var(--shadow-color);
	background: white;
	padding-left: 0.5em;
	padding-right: 0.5em;

	flex-grow: 0;
	flex-shrink: 0;

	height: var(--header-height);
	width: 100%;
	/*
	  Put it above the notes in order not to see random icons when hovering 
	  on notes that are hidden by the header
   */
	z-index:1
}

.profile-header > button {
	padding-left: 0.75em;
	padding-right: 0.75em;
	min-width: 4em;
	height: 100%;
}

.filler {
	flex-grow:1;
}

#searchbox {
	order: 1;
}

@media( max-width: 30em) {
	.header > input:focus ~ * {
		display: none;
	}
}
.profile-header > .spacer {
	margin-left: 1em;
}

.main-title {
	font-size: 2em;
}

.profile-header > .profile {
	padding-left: 1em;
}
