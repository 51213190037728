.modal-content.label-modal-grid {
    display: table
}

.label-modal-row {
    display: table-row;
}

.label-modal-element {
    display:  table-cell;
    padding-left: 0.5em;
    padding-right: 0.5em;
}